import { defineStore } from 'pinia'
// useMain  可以是 useUser、useCart 之类的名字 
// defineStore('main',{..}) 在devtools 就使用 main 这个名
export const useMain = defineStore('main', {
    // 相当于data
    state: () => {
      return {
        // 所有这些属性都将自动推断其类型,如果推断失败可以试下 as xxx
        counter: 0,
        name: 'Eduardo',
      }
    },
    // 相当于计算属性
    getters: {
      doubleCount: (state) => {
        return state.counter * 2
      },
    },
    // 相当于vuex的 mutation + action，可以同时写同步和异步的代码
    actions: {
      increment() {
        this.counter++
      },
      randomizeCounter() {
        setTimeout(() => {
          this.counter = Math.round(100 * Math.random())
        }, 0);
      },
    },
})
//系统配置
export const storeConfig:any = defineStore('config', {
  persist: {
    enabled: true,//开启数据持久化
    strategies: [
      {
        key: 'systemconfig',//给一个要保存的名称
        storage: localStorage,//sessionStorage / localStorage 存储方式
      }
    ]
  },
  state: () => {
    return {
      sysconfig: {
        domain: 'jp',
        domainUrl: 'http://test-jp.hemikj.com/',
        centerBaserurl: 'http://47.106.110.140:9101',
        tenantBaseurl: 'http://47.106.110.140:9102'
      } as any
    }
  },
  getters: {
    getState: (state) => state
  },
  actions: {
    setConfig(key:string,value:any) {
      this.sysconfig[key] = value
    }
  }
})
//登录状态
export const storeLogin:any = defineStore('login', {
  persist: {
    enabled: true,//开启数据持久化
    strategies: [
      {
        key: 'statelogin',//给一个要保存的名称
        storage: localStorage,//sessionStorage / localStorage 存储方式
      }
    ]
  },
  state: () => {
    return {
      userInfo: {} as any
    }
  },
  getters: {
    getState: (state) => state
  },
  actions: {
    setLoginInfo(userInfo:any) {
      this.userInfo = {...userInfo}
    },
    setUserInfo(key:string,value:any) {
      this.userInfo[key] = value
    },
    clearToken() {
      this.userInfo.token = ''
    }
  }
})
//缓存页面
export const storeKeepalive:any = defineStore('keepalive', {
  state: () => {
    return {
      includeList: [] as any,
      editableTabs: [] as any,
      currentRoute: 'home'
    }
  },
  getters: {
    getState: (state) => state
  },
  actions: {
    addIncludeList(routeitem:any) {
      this.includeList.push(routeitem.code)
      this.editableTabs.push(routeitem)
    },
    outIncludeList(routeitem:any) {
      let temparr = this.includeList
      let temparr1 = this.editableTabs
      this.includeList = temparr.filter((item:any)=>item !== routeitem.code)
      this.editableTabs = temparr1.filter((item:any)=>item.code !== routeitem.code)
    },
    setCurrentRoute(routecode:any) {
      this.currentRoute = routecode
    },
    initKeepalive () {
      this.includeList = []
      this.editableTabs = []
      this.currentRoute = 'home'
    }
  }
})
//面包屑标题
export const storeBread:any = defineStore('bread', {
  state: () => {
    return {
      level1: 'home',
      level3: ''
    }
  },
  getters: {
    getState: (state) => state
  },
  actions: {
    setBreadState(breadInfo:any) {
      this.level1 = breadInfo.level1
      this.level3 = breadInfo.level3
    }
  }
})